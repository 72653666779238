
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TemplateForm from '@/components/modals/shared/template/TemplateForm.vue'
import { dealsTemplates } from '@/definitions/deals/data'
import DealsFormDetails from '@/components/modals/deals/template/DealsFormDetails.vue'
import { useDeals } from '@/compositions/deals/useDeals'

export default defineComponent({
  components: {
    DealsFormDetails,
    TemplateForm,
    TmModal,
    TmButton,
  },
  setup() {
    const { openTemplateModal } = useDeals()

    const modal = ref()
    const handleChange = () => {
      modal.value.hideModal()
      openTemplateModal()
    }

    return {
      modal,
      dealsTemplates,
      handleChange,
    }
  },
})
